import React, { useState, useContext, useEffect } from "react"
import { getFormValues, reduxForm } from "redux-form"
import moment from "moment"
import {
    Button,
    Grid,
    Paper,
    Modal,
    Typography,
    withStyles,
} from "@material-ui/core"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import FilterList from "@material-ui/icons/FilterList"
import { OrdersContext } from "../../context/providers/OrdersProvider"
import FiltersMenu from "./filtersMenu/FiltersMenu"
import OrdersTable from "./OrdersTable"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import ImportModal from "./ImportModal"
import { constructLabel, constructSubLabel } from "../util"
import OrderSearch from "../search/orderSearch"
import DeleteOrderModal from "./DeleteOrderModal"
import ImportMappingModal from "./ImportMappingModal"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"
import { useFlags } from "launchdarkly-react-client-sdk"

const GACategory = "Orders"
const styles = theme => ({
    mainContainer: {
        padding: "2% 2% 2% 2%",
    },
    header: {
        paddingBottom: "15px",
        alignItems: "flex-end",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    toggleFilter: {
        height: "35px",
        minWidth: "327px",
    },
    selectedFilter: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white !important",
        height: "inherit",
        pointerEvents: "none",
        whiteSpace: "nowrap",
    },
    baseFilter: {
        color: "black",
        height: "inherit",
        whiteSpace: "nowrap",
    },
    input__field: {
        cursor: "pointer",
    },
    filtersMenu: {
        zIndex: 20,
    },
})

const OrdersPage = ({
    formattedLocations,
    locations,
    formValues = {},
    classes,
    language,
    filters,
}) => {
    const {
        setOrderStatus,
        orderStatus,
        ordersToDelete,
        setOrdersToDelete,
        setSelectAllClicked,
        ordersSummary,
        loading,
        setLoading,
        locationsFilters,
        setLocationsFilters,
        ordersFilterMenuOpen,
        setOrdersFilterMenuOpen,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        handleOrderStatusChange,
        initialFetch,
    } = useContext(OrdersContext)
    const { logGAEvent } = useGAContext()
    const { ordersPhaseTwo } = useFlags()

    useEffect(() => {
        logGAEvent(GACategory, "Page Mount")
        initialFetch()
    }, [])

    const [importModalOpen, setImportModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [importMappingModal, setImportMappingModal] = useState(false)

    const closeDeleteModal = () => {
        logGAEvent(GACategory, "Delete Modal Close")
        setDeleteModalOpen(false)
        setOrdersToDelete(null)
    }

    useEffect(() => {
        if (ordersToDelete) {
            setDeleteModalOpen(true)
            logGAEvent(GACategory, "Delete Modal Open")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersToDelete])

    return (
        <Grid item container className={classes.mainContainer}>
            <FiltersMenu
                open={ordersFilterMenuOpen}
                setOpen={setOrdersFilterMenuOpen}
                formattedLocations={formattedLocations}
                orderStatus={orderStatus}
                startDateFilter={startDateFilter}
                endDateFilter={endDateFilter}
                setStartDateFilter={setStartDateFilter}
                setEndDateFilter={setEndDateFilter}
                locationsFilters={locationsFilters}
                setLocationsFilters={setLocationsFilters}
            />
            <Grid
                item
                container
                pb={2}
                justifyContent="space-between"
                className={classes.header}
            >
                <Typography variant="h4">
                    <FormattedMessage
                        id="orders.title"
                        defaultMessage="Orders"
                    />
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        logGAEvent(GACategory, "Import Modal Open")
                        setImportModalOpen(true)
                    }}
                >
                    <FormattedMessage
                        id="orders.import"
                        defaultMessage="Import"
                    />
                </Button>
            </Grid>
            <Grid
                item
                container
                justifyContent="space-between"
                className={classes.header}
            >
                <Grid item container justifyContent="flex-start">
                    <OrderSearch
                        startDateFilter={startDateFilter}
                        endDateFilter={endDateFilter}
                        setSelectAllClicked={setSelectAllClicked}
                        gaCategory={GACategory}
                    />
                </Grid>
                <Grid item container justifyContent="center">
                    <ToggleButtonGroup
                        selected={true}
                        value={orderStatus}
                        exclusive
                        onChange={(evt, newFilter) =>
                            handleOrderStatusChange(newFilter)
                        }
                        name="orderFilters"
                        color="primary"
                        className={classes.toggleFilter}
                    >
                        {ordersPhaseTwo ? (
                            <ToggleButton
                                value="readyToBook"
                                disabled={
                                    ordersSummary?.readyToBook?.total === 0
                                }
                                classes={{
                                    selected: classes.selectedFilter,
                                    root: classes.baseFilter,
                                }}
                            >
                                <FormattedMessage
                                    id="orders.filters.readyToBook"
                                    defaultMessage="Ready to Book{total}"
                                    values={{
                                        total: ordersPhaseTwo
                                            ? ` (${ordersSummary?.readyToBook
                                                  ?.total ?? 0})`
                                            : "",
                                    }}
                                />
                            </ToggleButton>
                        ) : null}

                        <ToggleButton
                            value="actionRequired"
                            classes={{
                                selected: classes.selectedFilter,
                                root: classes.baseFilter,
                            }}
                        >
                            <FormattedMessage
                                id="orders.filters.actionRequired"
                                defaultMessage="Action Required{total}"
                                values={{
                                    total: ordersPhaseTwo
                                        ? ` (${ordersSummary?.unfulfilled
                                              ?.total ?? 0})`
                                        : "",
                                }}
                            />
                        </ToggleButton>

                        <ToggleButton
                            value="closed"
                            classes={{
                                selected: classes.selectedFilter,
                                root: classes.baseFilter,
                            }}
                        >
                            <FormattedMessage
                                id="orders.filters.closed"
                                defaultMessage="Closed{total}"
                                values={{
                                    total: ordersPhaseTwo
                                        ? ` (${ordersSummary?.closed?.total ??
                                              0})`
                                        : "",
                                }}
                            />
                        </ToggleButton>
                        <ToggleButton
                            value="archived"
                            classes={{
                                selected: classes.selectedFilter,
                                root: classes.baseFilter,
                            }}
                        >
                            <FormattedMessage
                                id="orders.filters.archived"
                                defaultMessage="Archived{total}"
                                values={{
                                    total: ordersPhaseTwo
                                        ? ` (${ordersSummary?.archived?.total ??
                                              0})`
                                        : "",
                                }}
                            />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item container justifyContent="flex-end">
                    <Button
                        color="primary"
                        startIcon={<FilterList />}
                        onClick={() => {
                            setOrdersFilterMenuOpen(!ordersFilterMenuOpen)
                        }}
                    >
                        <FormattedMessage
                            id="orders.filters"
                            defaultMessage="Filters"
                        />
                    </Button>
                </Grid>
            </Grid>
            <Grid
                item
                container
                component={Paper}
                style={{ minWidth: "1064px" }}
            >
                <OrdersTable
                    importModalOpen={importModalOpen}
                    setDeleteModalOpen={setDeleteModalOpen}
                    logGAEvent={logGAEvent}
                    gaCategory={GACategory}
                    selectedFilters={filters}
                    loading={loading}
                    setLoading={setLoading}
                />
            </Grid>
            <Modal
                open={importModalOpen}
                onClose={() => setImportModalOpen(false)}
            >
                <ImportModal
                    setModalOpen={setImportModalOpen}
                    setImportMappingModal={setImportMappingModal}
                    formattedLocations={formattedLocations}
                    locations={locations}
                    formValues={formValues}
                    language={language}
                    logGAEvent={logGAEvent}
                    gaCategory={GACategory}
                />
            </Modal>
            <Modal
                open={importMappingModal}
                onClose={() => setImportMappingModal(false)}
            >
                <ImportMappingModal
                    setImportMappingModal={setImportMappingModal}
                    setImportModalOpen={setImportModalOpen}
                    logGAEvent={logGAEvent}
                    startDate={startDateFilter}
                    setOrderStatus={setOrderStatus}
                    endDate={endDateFilter}
                />
            </Modal>
            <Modal open={deleteModalOpen} onClose={() => closeDeleteModal()}>
                <DeleteOrderModal
                    setDeleteModalOpen={setDeleteModalOpen}
                    startDate={startDateFilter}
                    endDate={endDateFilter}
                />
            </Modal>
        </Grid>
    )
}

const mapStateToProps = (state, props) => {
    const filters = state?.user?.profile?.preferences?.ordersPageFilters
        ? state?.user?.profile?.preferences?.ordersPageFilters
        : []
    const language = state?.user?.profile?.preferences?.language
    const locations = state?.user?.profile?.locations ?? []
    const formValues = getFormValues("orders")(state)
    const formattedLocations = locations
        .filter(
            item =>
                !item?.users[0]?.permissions?.suspended?.value &&
                !item?.isPending &&
                item?.locationType !== "FEDEX_FREIGHT_BOX" &&
                item?.users?.[0]?.permissions?.createRates?.value
        )
        .map(item => {
            const { city, state: addressState } =
                item?.shippingAddress?.address ?? {}
            const defaultNickname = `${item?.shippingAddress?.name} - ${city}, ${addressState}`
            return {
                label: constructLabel(item?.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item?.fedexFreightAccount,
                    item?.fedexBillToAccount,
                    props?.intl,
                    item?.locationType === "FEDEX_DIRECT" ||
                        item?.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                value: item?.cpgCode,
                fedexBillToAccount: item?.fedexBillToAccount,
                fedexFreightAccount: item?.fedexFreightAccount,
                isDefault: item?.isDefault,
                isFreightDirect: item?.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item?.locationType === "FEDEX_DIRECT_RETURNS",
                isFreightBox: item?.locationType === "FEDEX_FREIGHT_BOX",
            }
        })
    let billToLocation =
        locations.find(
            loc =>
                loc?._id ===
                    state?.user?.profile?.preferences?.defaultLocationId &&
                loc?.users?.[0]?.permissions?.createRates?.value &&
                !loc?.users?.[0]?.permissions?.suspended?.value &&
                !loc.isPending
        ) ||
        locations.find(
            loc =>
                loc?.users?.[0]?.permissions?.createRates?.value &&
                !loc?.users?.[0]?.permissions?.suspended?.value &&
                !loc.isPending
        )
    billToLocation = formattedLocations.find(
        formattedLocation =>
            formattedLocation?.value === billToLocation?.cpgCode
    )
    const isThirdPartyOnly =
        !billToLocation?.isShipperEnabled && billToLocation?.is3rdPartyEnabled
    return {
        locations,
        formattedLocations,
        formValues,
        language,
        initialValues: {
            mode: isThirdPartyOnly ? "thirdParty" : "outbound",
            billToLocation,
            startDate: moment(
                moment()
                    .subtract(90, "days")
                    .tz(moment.tz.guess())
                    .format("YYYY-MM-DD")
            ),

            endDate: moment(
                moment()
                    .tz(moment.tz.guess())
                    .format("YYYY-MM-DD")
            ),
        },
        filters,
    }
}

export default withStyles(styles)(
    injectIntl(
        connect(
            mapStateToProps,
            null
        )(
            reduxForm({
                form: "orders", // a unique identifier for this form
                enableReinitialize: true,
            })(OrdersPage)
        )
    )
)
