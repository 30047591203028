import moment from "moment"
import createSelector from "re-reselect"
import { newFreightDirectHandlingUnit } from "../../../reducers/search"
import qs from "qs"
import { createGoodInitialValues } from "../../documents/USMCA/selectors"

const shipmentIdSelector = (state, props) =>
    props?.match?.params?.shipmentId ?? ""

const returnModeSelector = (state, props) =>
    props?.match?.params?.mode === "return" ?? false

const quoteSelector = createSelector(
    state => state.redesign.activeRates,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const shipmentSelector = createSelector(
    state => state.shipment.list,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const contactIdSelector = (state, props) => {
    const { to: contactId } = qs.parse(props?.location?.search, {
        ignoreQueryPrefix: true,
    })
    return contactId ?? ""
}

const contactSelector = createSelector(
    contactIdSelector,
    state => state?.addressBook?.addresses,
    (contactId, contacts) => {
        if (!contactId || !contacts || !contacts.length) return undefined
        return contacts.find(item => item?.contact?.id === contactId)
    }
)(contactIdSelector)

export const billToLocationSelector = createSelector(
    quoteSelector,
    state => state?.user?.profile?.locations,
    state => state?.user?.profile?.preferences?.defaultLocationId,
    returnModeSelector,
    (quote, locations = [], defaultLocationId, isReturnMode) => {
        if (isReturnMode) {
            return locations.find(
                loc => loc.locationType === "FEDEX_DIRECT_RETURNS"
            )
        } else {
            return (
                locations.find(
                    loc => (loc?.cpgCode ?? "") === quote?.search?.cpg
                ) ||
                locations.find(
                    loc =>
                        loc?._id === defaultLocationId &&
                        loc?.users?.[0]?.permissions?.createRates?.value &&
                        !loc?.users?.[0]?.permissions?.suspended?.value &&
                        !loc.isPending
                ) ||
                locations.find(
                    loc =>
                        loc?.users?.[0]?.permissions?.createRates?.value &&
                        !loc?.users?.[0]?.permissions?.suspended?.value &&
                        !loc.isPending
                )
            )
        }
    }
)(shipmentIdSelector)

export const nextBusinessDay = () => {
    if (moment().day() === 5) {
        return moment.utc(
            moment()
                .add(3, "days")
                .format("YYYY-MM-DD")
        )
    } else if (moment().day() === 6) {
        return moment.utc(
            moment()
                .add(2, "days")
                .format("YYYY-MM-DD")
        )
    } else {
        return moment.utc(
            moment()
                .add(1, "days")
                .format("YYYY-MM-DD")
        )
    }
}

export function lookupContact(address, id) {
    const contact = (address?.contacts ?? []).find(item => item._id === id)
    return transformContact(contact)
}

const transformContact = contact => ({
    _id: contact?._id,
    name: contact?.name,
    phone: contact?.phone,
    email: contact?.email?.email_address,
})

const orderSelector = (state, props) => props.order

const orderIdSelector = (state, props) => orderSelector(state, props)?.id ?? ""

const orderLocationSelector = createSelector(
    orderSelector,
    state => state?.user?.profile?.locations,
    (order, locations) => locations?.find(x => x?.cpgCode === order?.cpg)
)(orderIdSelector)

const mapOrderContactToForm = (record, pickupRecord) => {
    let retVal
    if (!record) {
        retVal = {
            address: {
                country: "US",
            },
            shippingAddress: {
                address: {
                    country: "US",
                },
            },
        }
    } else {
        retVal = {
            address: {
                ...record?.address,
                country: record?.address?.country ?? "US",
            },
            shippingAddress: {
                address: {
                    ...record?.address,
                    country: record?.address?.country ?? "US",
                },
                name: record?.name,
            },
            contact: record?.contact,
        }
    }
    if (pickupRecord) {
        retVal.pickupContact = pickupRecord?.pickupContact
        retVal.note = pickupRecord?.pickupRemarks
    }
    return retVal
}

export const orderToFormSelector = createSelector(
    orderSelector,
    orderLocationSelector,
    state => state?.user?.profile,
    (order, billToLocation, user) => {
        const shippingContact = {
            name: [user?.firstname, user?.lastname].filter(x => x).join(" "),
            phone: user?.phone_number,
            email: {
                email_address: user?.email?.email_address,
            },
        }
        const isShipperEnabled = billToLocation?.isShipperEnabled
        const isFreightDirectLocation =
            billToLocation?.locationType === "FEDEX_DIRECT"
        const isFreightBoxLocation =
            billToLocation?.locationType === "FEDEX_FREIGHT_BOX"
        let mode
        switch (order?.mode) {
            case "OUTBOUND":
                mode = "outbound"
                break
            case "INBOUND":
                mode = "inbound"
                break
            case "THIRDPARTY":
                mode = "thirdParty"
                break
            default:
                mode = "outbound"
                break
        }
        const isFreightDirectReturns =
            isFreightDirectLocation && mode === "inbound"

        const hazmatEmergency = user?.preferences?.hazmatEmergencyContact ?? {}
        const exportCustomsBrokerageInfo = { address: { country: "US" } }
        const importCustomsBrokerageInfo = { address: { country: "US" } }

        const minimumPickupDate = isFreightDirectReturns
            ? nextBusinessDay()
            : moment.utc().startOf("day")

        let pickupDate = minimumPickupDate
        if (order?.pickupInformation?.pickupDate !== null) {
            pickupDate = moment.utc(order?.pickupInformation?.pickupDate)
            if (pickupDate.isBefore(minimumPickupDate)) {
                pickupDate = minimumPickupDate
            }
        }

        let correctPaymentType = null
        if (
            isShipperEnabled &&
            !isFreightDirectLocation &&
            !isFreightDirectReturns &&
            !isFreightBoxLocation
        ) {
            if (mode === "inbound") {
                correctPaymentType = "COLLECT"
            } else if (mode === "outbound") {
                correctPaymentType = "PREPAID"
            }
        }

        const handlingUnits = !order?.handlingUnits?.length
            ? [newFreightDirectHandlingUnit()]
            : order?.handlingUnits?.map(x => {
                  x.count = x.count ?? 1
                  x.items[0].weight = Math.ceil(Number(x.totalWeight / x.count))
                  x.doNotStack = !x.stackable
                  x.items[0].freightDirectPieces = [{ count: 1, weight: null }]
                  return x
              })

        return {
            associatedOrder: order?.id,
            cpg: billToLocation?.cpgCode,
            billToLocation: billToLocation?.cpgCode,
            selectedLocation: billToLocation,
            mode,
            declaredValueExpanded:
                user?.preferences?.declaredValueExpanded ?? false,
            preferredCurrencyCode: order?.preferredCurrencyCode ?? "USD",
            preferredSystemOfMeasurement:
                order?.preferredSystemOfMeasurement ?? "IMPERIAL",
            includeThirdParty: true,
            isFreightDirect: isFreightDirectLocation,
            isFreightBox: isFreightBoxLocation,
            isFreightDirectReturns,
            hideThirdPartyRates: false,
            hideLTLRates: false,
            hideVolumeRates: false,
            alerts: {
                user: user?.preferences?.alerts,
            },
            handlingUnits,
            hazmatEmergency,
            pickupAccessorials: order?.origin?.accessorials ?? ["DOCKPU"],
            deliveryAccessorials: order?.destination?.accessorials ?? [
                "DOCKDEL",
            ],
            shippingContact,
            isInBondShipment: order?.isInBondShipment,
            isQuickQuote: false,
            totalLinearFeet: 0,
            userProvidedTotalLinearFeet: false,
            capLoadTotalLinearFeet: 0,
            volumeTotalLinearFeet: 0,
            isOverLTLLimit: false,
            showWarning: false,
            exportCustomsBrokerageInfo,
            importCustomsBrokerageInfo,
            thirdPartyOnly: !billToLocation?.isShipperEnabled,
            pickupDate,
            paymentType: order?.paymentType ?? correctPaymentType,
            origin:
                mode === "outbound"
                    ? { ...billToLocation, contact: shippingContact }
                    : mapOrderContactToForm(
                          order?.origin,
                          order?.pickupInformation
                      ),
            destination:
                mode === "inbound"
                    ? { ...billToLocation, contact: shippingContact }
                    : mapOrderContactToForm(order?.destination),
            identifiers: {
                orderNumber: order?.orderNumber,
            },
            usmca: {
                goods: createGoodInitialValues(handlingUnits),
            },
        }
    }
)(orderIdSelector)

const timeConverter = time => {
    if (
        time &&
        !time.toLowerCase().includes("am") &&
        !time.toLowerCase().includes("pm")
    ) {
        return moment(time, "HH:mm").format("h:mm A")
    }
    return time
}

export const formSelector = createSelector(
    quoteSelector,
    shipmentIdSelector,
    billToLocationSelector,
    contactSelector,
    returnModeSelector,
    shipmentSelector,
    state => state?.user?.profile,
    (
        quote,
        shipmentId,
        billToLocation,
        contact,
        isReturnMode,
        shipment,
        user
    ) => {
        const shippingContact = {
            name: [user?.firstname, user?.lastname].filter(x => x).join(" "),
            phone: user?.phone_number,
            email: {
                email_address: user?.email?.email_address,
            },
        }

        const isThirdPartyOnly =
            !billToLocation?.isShipperEnabled &&
            billToLocation?.is3rdPartyEnabled

        const isFreightBox =
            billToLocation?.locationType === "FEDEX_FREIGHT_BOX"

        const defaultShippingAddress = {
            shippingAddress: {
                address: {
                    country: "US",
                },
            },
        }
        if (!shipmentId) {
            const isFreightDirectReturns =
                billToLocation?.locationType === "FEDEX_DIRECT_RETURNS"

            const isFreightDirect =
                billToLocation?.locationType === "FEDEX_DIRECT"

            const isShipperEnabled = billToLocation?.isShipperEnabled

            const calculatedMode =
                isFreightDirectReturns && !isThirdPartyOnly
                    ? "inbound"
                    : isThirdPartyOnly
                    ? "thirdParty"
                    : "outbound"

            let correctPaymentType = null

            if (
                isShipperEnabled &&
                !isFreightDirect &&
                !isFreightDirectReturns &&
                !isFreightBox
            ) {
                if (calculatedMode === "inbound") {
                    correctPaymentType = "COLLECT"
                } else if (calculatedMode === "outbound") {
                    correctPaymentType = "PREPAID"
                }
            }

            const pickupAccessorials = ["DOCKPU"]
            const deliveryAccessorials = ["DOCKDEL"]

            const hazmatEmergency =
                user?.preferences?.hazmatEmergencyContact ?? {}
            const exportCustomsBrokerageInfo = { address: { country: "US" } }
            const importCustomsBrokerageInfo = { address: { country: "US" } }

            const destinationContact = contact
                ? {
                      shippingAddress: {
                          address: { ...contact?.address },
                          name: contact?.name,
                      },
                      contact: {
                          name: contact?.contact?.name,
                          phone: {
                              phone_number:
                                  contact?.contact?.phone?.phoneNumber,
                              extension: contact?.contact?.phone?.extension,
                          },
                          email: {
                              email_address:
                                  contact?.contact?.email?.emailAddress,
                          },
                      },
                  }
                : defaultShippingAddress

            return {
                shouldDisPlayContact: !!destinationContact?.address?.postalCode,
                includeThirdParty: true,
                shouldReset: false,
                alerts: {
                    user: user?.preferences?.alerts,
                },
                declaredValueExpanded:
                    user?.preferences?.declaredValueExpanded ?? false,
                hazmatEmergency,
                pickupAccessorials,
                deliveryAccessorials,
                shippingContact,
                selectedLocation: billToLocation,
                mode: calculatedMode,
                origin:
                    isShipperEnabled && !isFreightDirectReturns
                        ? { ...billToLocation, contact: shippingContact }
                        : defaultShippingAddress,
                destination: isFreightDirectReturns
                    ? { ...billToLocation, contact: shippingContact }
                    : destinationContact,
                pickupDate: isFreightDirectReturns
                    ? nextBusinessDay()
                    : moment.utc(moment().format("YYYY-MM-DD")),
                billToLocation: billToLocation?.cpgCode,
                cpg: billToLocation?.cpgCode,
                isFedexFileEEI: quote?.search?.isFedexFileEEI,
                isFreightDirect,
                isFreightBox,
                isFreightDirectReturns,
                itns: quote?.search?.itns,
                handlingUnits: [newFreightDirectHandlingUnit()],
                isQuickQuote: false,
                totalLinearFeet: 0,
                userProvidedTotalLinearFeet: false,
                capLoadTotalLinearFeet: 0,
                volumeTotalLinearFeet: 0,
                isOverLTLLimit: false,
                showWarning: false,
                preferredCurrencyCode: "USD",
                preferredSystemOfMeasurement: "IMPERIAL",
                exportCustomsBrokerageInfo,
                importCustomsBrokerageInfo,
                paymentType: correctPaymentType,
            }
        }

        const isFreightDirectReturns =
            billToLocation?.locationType === "FEDEX_DIRECT_RETURNS" ||
            quote?.search?.isFreightDirectReturn

        const isFreightDirect =
            billToLocation?.locationType === "FEDEX_DIRECT" ||
            quote?.search?.isFreightDirect

        const isQuickRate = quote?.search?.isQuickRate
        const shipmentDestination = shipment?.shipment?.destination
        const destination = {
            shippingAddress: {
                address: { ...shipmentDestination?.address },
                name: shipmentDestination?.name,
            },
            contact: shipmentDestination?.contact,
            readyTime: isFreightDirectReturns ? "7:00 AM" : null,
            closeTime: isFreightDirectReturns ? "8:00 PM" : null,
        }
        let handlingUnits =
            (isReturnMode ? shipment?.handlingUnits : quote?.handlingUnits) ??
            []
        const mode =
            (isReturnMode || isFreightDirectReturns
                ? "inbound"
                : quote?.search?.mode) ?? "outbound"

        const correctOutboundPickupContact =
            billToLocation?.pickupContact?.name ||
            billToLocation?.pickupContact?.email?.email_address ||
            billToLocation?.pickupContact?.phone?.phone_number
                ? billToLocation?.pickupContact
                : quote?.search?.origin?.contact

        const correctInboundPickupContact =
            quote?.search?.origin?.pickupContact?.name ||
            quote?.search?.origin?.pickupContact?.email?.email_address ||
            quote?.search?.origin?.pickupContact?.phone?.phone_number
                ? quote?.search?.origin?.pickupContact
                : quote?.search?.origin?.contact

        let secondSelectedLocation = null
        if (quote?.search?.secondCpg) {
            secondSelectedLocation = user?.locations.find(
                location => location?.cpgCode === quote?.search?.secondCpg
            )
        }

        return {
            ...quote?.search,
            mode,
            includeThirdParty: true,
            hideThirdPartyRates: false,
            hideLTLRates: false,
            hideVolumeRates: false,
            isQuickRate,
            isReturnMode,
            declaredValueExpanded:
                user?.preferences?.declaredValueExpanded ?? false,
            proNumber: shipment?.identifiers?.proNumber,
            handlingUnits,
            pickupDate: isReturnMode
                ? nextBusinessDay()
                : moment.utc(quote?.search?.pickupDate),
            alerts: {
                user: user?.preferences?.alerts,
            },
            origin: isReturnMode
                ? destination
                : mode === "outbound"
                ? {
                      ...quote?.search?.origin,
                      readyTime: billToLocation?.readyTime,
                      closeTime: billToLocation?.closeTime,
                      pickupContact: correctOutboundPickupContact,
                  }
                : {
                      ...quote?.search?.origin,
                      readyTime: timeConverter(
                          quote?.search?.origin?.readyTime
                      ),
                      closeTime: timeConverter(
                          quote?.search?.origin?.closeTime
                      ),
                      pickupContact: correctInboundPickupContact,
                  },
            destination: isReturnMode
                ? { ...billToLocation, contact: shippingContact }
                : mode === "inbound" && !isThirdPartyOnly
                ? {
                      ...quote?.search?.destination,
                      shippingAddress: billToLocation?.shippingAddress,
                  }
                : quote?.search?.destination,
            cpg: isReturnMode ? billToLocation?.cpgCode : quote?.search?.cpg,
            billToLocation: isReturnMode
                ? billToLocation?.cpgCode
                : quote?.search?.cpg,
            thirdPartyOnly: !billToLocation?.isShipperEnabled,
            isFedexFileEEI: quote?.search?.isFedexFileEEI,
            isFreightBox: !quote?.result?.rateQuotes?.length
                ? billToLocation?.locationType === "FEDEX_FREIGHT_BOX"
                : quote.result.rateQuotes.some(
                      x => x.mode === "LTL_FREIGHT_BOX"
                  ),
            isFreightDirect: isFreightDirect,
            isFreightDirectReturns: isReturnMode
                ? true
                : isFreightDirectReturns,
            itns: quote?.search?.itns,
            preferredCurrencyCode: quote?.search?.preferredCurrencyCode,
            preferredSystemOfMeasurement:
                quote?.search?.preferredSystemOfMeasurement,
            selectedLocation: billToLocation,
            secondSelectedLocation: secondSelectedLocation,
            paymentType: quote?.search?.paymentType,
            paymentAccount:
                quote?.search?.paymentType === "THIRD_PARTY"
                    ? {
                          address: {
                              country: "US",
                          },
                      }
                    : null,
            usmca: {
                goods: createGoodInitialValues(handlingUnits),
            },
        }
    }
)(shipmentIdSelector)

export const locationPermissionSelector = createSelector(
    billToLocationSelector,
    billToLocation => ({
        canCreateRates: !!billToLocation?.users?.[0]?.permissions?.createRates
            ?.value,
        canCreateShipments: !!billToLocation?.users?.[0]?.permissions
            ?.createShipments?.value,
        canViewRateAmounts: !!billToLocation?.users?.[0]?.permissions
            ?.viewRateAmounts?.value,
    })
)(shipmentIdSelector)
