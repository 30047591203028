import React, { useState, createContext, useContext, useMemo } from "react"
import { debounce } from "lodash"
import { FormattedMessage } from "react-intl"
import { goFetch, goFetchV2 } from "../../http"
import { useSnackbarContext } from "./snackbarProvider"
import { useGAContext } from "./GoogleAnalyticsProvider"
import moment from "moment"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useUsersContext } from "./UserProvider"

const GA_CATEGORY = "Orders"

export const OrdersContext = createContext()

export const useOrdersContext = () => {
    const ordersContext = useContext(OrdersContext)
    if (!ordersContext) {
        throw new Error("Cannot use order context ouside of OrderProvider")
    }
    return ordersContext
}

export default function OrdersProvider({ children }) {
    const { updateUserPreferences, getUserPreferences } = useUsersContext()
    const { ordersPhaseTwo } = useFlags()
    // PAGINATION
    const [pageNumber, setPageNumber] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // FILTERS
    const [startDateFilter, setStartDateFilter] = useState(
        moment().subtract(90, "d")
    )
    const [endDateFilter, setEndDateFilter] = useState(moment())
    const [locationsFilters, setLocationsFilters] = useState([])
    const [serviceLevelFilters, setServiceLevelFilters] = useState([])
    const [deliveryTimeFilters, setDeliveryTimeFilters] = useState([])
    const [ordersForPickup, setOrdersForPickup] = useState([])
    // DATA
    const [ordersList, setOrdersList] = useState([])
    const [orderCount, setOrderCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [ordersToDelete, setOrdersToDelete] = useState(null)
    const [searchQuery, setSearchQuery] = useState("")
    const [orderMappingData, setOrderMappingData] = useState(null)
    const [errors, setErrors] = useState([])
    const [isBookingOrder, setIsBookingOrder] = useState(false)
    const [bulkOrders, setBulkOrders] = useState([])
    const [selectAllClicked, setSelectAllClicked] = useState(false)
    const [ordersSummary, setOrdersSummary] = useState(null)
    const [ordersRatesHeaders, setOrdersRatesHeaders] = useState([])
    const [orderStatus, setOrderStatus] = useState(null)
    const [ordersFilterMenuOpen, setOrdersFilterMenuOpen] = useState(false)
    const { openSnackbar } = useSnackbarContext()
    const { logGAEvent, logGAError } = useGAContext()

    const [isFetchingRates, setIsFetchingRates] = useState(false)

    const getOrderById = async orderId => {
        try {
            const { data } = await goFetch(
                `/orders/${orderId}`,
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            return data
        } catch (e) {
            console.error(`Error receieving order ${orderId}`, e)
            return null
        }
    }

    const uploadFile = async (cpg, mode, ordersFile) => {
        const formData = new FormData()
        formData.append("cpg", cpg)
        formData.append("mode", mode)
        formData.append("file", ordersFile)
        const result = await goFetch(
            `/orders/import/uploadfile`,
            {
                method: "POST",
                data: formData,
                credentials: "same-origin",
                headers: {
                    "content-type": "multipart/form-data",
                    "cache-control": "no-cache",
                },
            },
            true
        )

        return result
    }

    const parseFilters = filters => {
        const deliveryTimeOptions = ["morning", "endofDay"]
        const serviceLevelOptions = [
            "fxnl",
            "fxfe",
            "basic",
            "basic_appt",
            "std",
            "premium",
        ]
        const loc = []
        const delTime = []
        const servLevel = []
        for (let filter of filters ?? []) {
            if (deliveryTimeOptions.includes(filter)) {
                delTime.push(filter)
            } else if (serviceLevelOptions.includes(filter)) {
                servLevel.push(filter)
            } else {
                loc.push(filter)
            }
        }
        setDeliveryTimeFilters(delTime)
        setServiceLevelFilters(servLevel)
        setLocationsFilters(loc)
        return {
            newLocationFilters: loc,
            newRateFilters: [...delTime, ...servLevel],
        }
    }

    const initialFetch = async () => {
        const { ordersPageFilters } = await getUserPreferences()
        const { newLocationFilters, newRateFilters } = parseFilters(
            ordersPageFilters
        )
        const summaryData = await fetchOrdersSummaryData({ newLocationFilters })
        setPageNumber(1)
        setSelectAllClicked(false)
        setBulkOrders([])
        if (ordersPhaseTwo && summaryData?.readyToBookCount?.total > 0) {
            setOrderStatus("readyToBook")
            await getOrdersList({
                newOrderStatus: "readyToBook",
                newLocationFilters,
                newRateFilters,
            })
        } else {
            setOrderStatus("actionRequired")
            await getOrdersList({
                newOrderStatus: "actionRequired",
                newLocationFilters,
            })
        }
    }
    const createOrders = async createOrdersObj => {
        const result = await goFetch(
            `/orders/import/createorders`,
            {
                method: "POST",
                data: createOrdersObj,
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
            },
            true
        )
        return result
    }

    const markBulkOrdersAsUnfulfilled = async orderIds => {
        const result = await goFetch(
            `/orders/updateBulkUnfulfilled`,
            {
                method: "PUT",
                data: orderIds,
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
            },
            true
        )
        return result
    }

    const applyFilters = async () => {
        setOrdersFilterMenuOpen(false)
        setSearchQuery("")
        await updateUserPreferences([
            ...serviceLevelFilters,
            ...deliveryTimeFilters,
            ...locationsFilters,
        ])
        try {
            await getOrdersList({})
            await fetchOrdersSummaryData({})
        } catch (error) {
            logGAError("Orders: Apply Filters", error)
            setOrdersFilterMenuOpen(true)
        }
    }

    const clearFilters = async () => {
        setLocationsFilters([])
        setDeliveryTimeFilters([])
        setServiceLevelFilters([])
        setOrdersFilterMenuOpen(false)
        updateUserPreferences([])
        setSearchQuery("")
        fetchOrdersSummaryData({ newLocationFilters: [], query: "" })
        getOrdersList({ newLocationFilters: [], newRateFilters: [] })
    }

    const getOrder = async orderId => {
        let order = ordersList?.find(x => x?.id === orderId)
        if (!order) {
            order = await getOrderById(orderId)
        }
        return order
    }

    const deleteBulkOrders = async ordersToDelete => {
        let orderIDsToDelete = []
        ordersToDelete.forEach(order => orderIDsToDelete.push(order.id))
        try {
            setLoading(true)
            await goFetch(
                `/orders/`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: orderIDsToDelete,
                },
                true
            )
            setBulkOrders([])
            setSelectAllClicked(false)
            logGAEvent("Orders", "Bulk Delete", orderIDsToDelete.length)
            openSnackbar(
                "success",
                <FormattedMessage
                    id="orders.delete.bulkDelete.success"
                    defaultMessage="Orders Successfully Deleted"
                />,
                2000
            )
        } catch (e) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="orders.delete.Bulkdelete.failure"
                    defaultMessage="Failure deleting orders"
                />,
                2000
            )
            setSelectAllClicked(false)
            setBulkOrders([])
        } finally {
            await fetchOrdersSummaryData({})
            await getOrdersList({})
            if (orderCount === 1) {
                setSearchQuery("")
            }
            setLoading(false)
        }
    }

    const archiveBulkOrders = async ({ ordersToArchive, archiveStatus }) => {
        setLoading(true)
        let orderIDsToArchive = []
        ordersToArchive.forEach(order => orderIDsToArchive.push(order.id))
        try {
            await goFetch(
                `/orders/archive`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        isArchived: archiveStatus,
                        ordersToArchive: orderIDsToArchive,
                    },
                },
                true
            )
            if (archiveStatus) {
                logGAEvent("Orders", "Bulk Action", "Orders Archived")
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="orders.archive.archive.success"
                        defaultMessage="Order {field} Archived"
                        values={{ field: "Successfully" }}
                    />,
                    2000
                )
            } else {
                logGAEvent("Orders", "Bulk Action", "Orders Unarchived")
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="orders.archive.unarchive.success"
                        defaultMessage="Order {field} Unarchived"
                        values={{ field: "Successfully" }}
                    />,
                    2000
                )
            }
            setBulkOrders([])
            setSelectAllClicked(false)
        } catch (e) {
            if (archiveStatus) {
                console.error("Error archiving order", e)
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="orders.archive.archive.failure"
                        defaultMessage="Failure archiving order {field}"
                        values={{ field: "" }}
                    />,
                    2000
                )
            } else {
                console.error("Error unarchiving order", e)
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="orders.archive.unarchive.failure"
                        defaultMessage="Failure unarchiving order {field}"
                        values={{ field: "" }}
                    />,
                    2000
                )
            }
            setSelectAllClicked(false)
            setBulkOrders([])
        } finally {
            await fetchOrdersSummaryData({})
            await getOrdersList({})
            setLoading(false)
        }
    }

    const archiveOrder = async (order, startDate, endDate) => {
        setLoading(true)
        const orderId = order.id
        const newArchiveStatus = !order.isArchived
        const orderNumber = order.orderNumber

        try {
            await goFetch(
                `/orders/${orderId}/archive`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: { isArchived: newArchiveStatus },
                },
                true
            )
            if (newArchiveStatus) {
                logGAEvent("Orders", "Order archived")
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="orders.archive.archive.success"
                        defaultMessage="Order {field} Archived"
                        values={{ field: orderNumber }}
                    />,
                    2000
                )
            } else {
                logGAEvent("Orders", "Order unarchived")
                openSnackbar(
                    "success",
                    <FormattedMessage
                        id="orders.archive.unarchive.success"
                        defaultMessage="Order {field} Unarchived"
                        values={{ field: orderNumber }}
                    />,
                    2000
                )
            }
        } catch (e) {
            if (newArchiveStatus) {
                console.error("Error archiving order", e)
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="orders.archive.archive.failure"
                        defaultMessage="Failure archiving order {field}"
                        values={{ field: orderNumber }}
                    />,
                    2000
                )
            } else {
                console.error("Error unarchiving order", e)
                openSnackbar(
                    "error",
                    <FormattedMessage
                        id="orders.archive.unarchive.failure"
                        defaultMessage="Failure unarchiving order {field}"
                        values={{ field: orderNumber }}
                    />,
                    2000
                )
            }
        } finally {
            await fetchOrdersSummaryData({})
            await getOrdersList({})
        }
        setLoading(false)
    }

    const getOrdersList = async ({
        newPageNumber,
        newRowsPerPage,
        newOrderStatus,
        newLocationFilters,
        newRateFilters,
        newStartDate,
        newEndDate,
        query,
    }) => {
        setLoading(true)
        const timeZoneOffset = new Date().getTimezoneOffset()
        try {
            setOrdersList([])
            // base url w/ date + page filters
            let url = `/orders?pageSize=${newRowsPerPage ??
                rowsPerPage}&pageNumber=${newPageNumber ??
                pageNumber}&startUploadDate=${moment(
                newStartDate ?? startDateFilter
            ).format("YYYY-MM-DD[T]HH:mm:ss")}&endUploadDate=${moment(
                newEndDate ?? endDateFilter
            ).format(
                "YYYY-MM-DD"
            )}T23:59:59&userTimezoneOffset=${timeZoneOffset}`

            // search bar query
            if (query) {
                url = url.concat(`&q=${query}`)
                setPageNumber(1)
            }
            // view filter
            switch (newOrderStatus ?? orderStatus) {
                case "closed":
                    url = url.concat("&isFulfilled=true&archived=false")
                    break
                case "actionRequired":
                    url = ordersPhaseTwo
                        ? url.concat(
                              "&readyToBook=false&isFulfilled=false&archived=false"
                          )
                        : url.concat("&isFulfilled=false&archived=false")
                    break
                case "archived":
                    url = url.concat("&archived=true")
                    break
                case "readyToBook":
                    url = url.concat(
                        "&isFulfilled=false&archived=false&readyToBook=true"
                    )
                    break
                default:
                    break
            }
            // location filter
            const locFilter = newLocationFilters ?? locationsFilters
            if (locFilter) {
                for (let loc of locFilter) {
                    url = url.concat(`&cpg=${loc}`)
                }
            }

            //FETCH ORDERS
            const {
                data: { list, totalCount },
            } = await goFetch(
                url,
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            // FETCH RATES for "Ready to Book"
            if (
                newOrderStatus
                    ? newOrderStatus === "readyToBook"
                    : orderStatus === "readyToBook"
            ) {
                const ratesResponse = await fetchOrderRates(
                    list?.map(order => order.id),
                    newRateFilters
                )
                const combinedOrdersRates = list.map(order => {
                    const rate = ratesResponse.find(
                        rateResponse =>
                            rateResponse.search.associatedOrder === order.id
                    )
                    return {
                        ...order,
                        rates: rate.result.rateQuotes,
                        ...rate,
                    }
                })
                parseOrdersRateHeaders(combinedOrdersRates)
                setOrdersList(combinedOrdersRates)
                setOrderCount(totalCount)
            } else {
                setOrdersList(list)
                setOrderCount(totalCount)
            }
        } catch (e) {
            setOrdersList([])
            setOrderCount(0)
        }
        setLoading(false)
    }

    const parseOrdersRateHeaders = orders => {
        const uniqueRates = new Set()
        const unsortedHeaders = []
        for (let order of orders) {
            const rates = order?.rates
            for (let rate of rates) {
                uniqueRates.add()
                const key = getRateKey(rate)
                const deliveryDate = moment(rate?.deliveryDateTime).format(
                    "YYYY-MM-DD"
                )
                const carrierCode = rate?.carrierCode
                const mode = rate?.mode
                const serviceLevel = rate?.serviceLevel?.description
                const orderRateDetails = {
                    key,
                    serviceLevel,
                    carrierCode,
                    deliveryDate,
                    mode,
                }
                if (!uniqueRates.has(key)) {
                    uniqueRates.add(key)
                    unsortedHeaders.push(orderRateDetails)
                }
            }
        }
        setOrdersRatesHeaders(sortOrdersRatesHeaders(unsortedHeaders))
    }

    const getRateKey = rate => {
        const deliveryDate = moment(rate?.deliveryDateTime).format("YYYY-MM-DD")
        const carrierCode = rate?.carrierCode
        const mode = rate?.mode
        const serviceLevel = rate?.serviceLevel?.description
        const direction = rate?.direction
        const key = `${deliveryDate}-${carrierCode}-${mode}-${serviceLevel}-${direction}`
        return key
    }

    const sortOrdersRatesHeaders = headers => {
        const serviceLevelOrder = [
            "Basic",
            "Basic by Appointment",
            "Standard",
            "Premium",
        ]

        headers.sort((a, b) => {
            const dateComparison = a.deliveryDate.localeCompare(b.deliveryDate)
            if (dateComparison !== 0) return dateComparison

            const modeComparison = b.mode.localeCompare(a.mode)
            if (modeComparison !== 0) return modeComparison

            const carrierCodeComparison = a.carrierCode.localeCompare(
                b.carrierCode
            )
            if (carrierCodeComparison !== 0) return carrierCodeComparison

            const indexA = serviceLevelOrder.indexOf(a.serviceLevel)
            const indexB = serviceLevelOrder.indexOf(b.serviceLevel)
            if (indexA === -1 && indexB === -1) {
                return a.serviceLevel.localeCompare(b.serviceLevel) // Both are unknown, sort alphabetically
            } else if (indexA === -1) {
                return 1 // a is unknown, place it after known levels
            } else if (indexB === -1) {
                return -1 // b is unknown, place it after known levels
            } else {
                return indexA - indexB // Compare by custom order
            }
        })

        return headers
    }

    const fetchOrderRates = async (orderIds, newRateFilters) => {
        const selectedFilters = newRateFilters ?? [
            ...deliveryTimeFilters,
            ...serviceLevelFilters,
        ]
        let suffix = ""
        if (selectedFilters.length > 0) {
            selectedFilters.forEach(
                filter => (suffix = suffix.concat(`&${filter}=true`))
            )
        }
        const { data } = await goFetchV2(
            "/quotes/orders?" + suffix,
            {
                method: "PUT",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
                data: orderIds,
            },
            true
        )
        return data
    }

    const fetchOrdersSummaryData = async ({ query, newLocationFilters }) => {
        if (!ordersPhaseTwo) return
        const timeZoneOffset = new Date().getTimezoneOffset()
        try {
            let url = `/orders/summary?startUploadDate=${moment(
                startDateFilter
            ).format("YYYY-MM-DD[T]HH:mm:ss")}&endUploadDate=${moment(
                endDateFilter
            ).format(
                "YYYY-MM-DD"
            )}T23:59:59&userTimezoneOffset=${timeZoneOffset}`

            if (query) {
                url = url.concat(`&q=${query}`)
            }

            const locFilter = newLocationFilters ?? locationsFilters
            if (locFilter) {
                for (let loc of locFilter) {
                    url = url.concat(`&cpg=${loc}`)
                }
            }

            const { data } = await goFetch(
                url,
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            setOrdersSummary(data)
            return data
        } catch (e) {
            setOrdersSummary({
                readyToBook: { total: 0 },
                archived: { total: 0 },
                closed: { total: 0 },
                unfulfilled: { total: 0 },
            })
            return null
        }
    }

    const savePickupSelection = async selection => {
        await goFetch(
            `/orders/pickup/${selection}`,
            {
                method: "POST",
                data: ordersForPickup,
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
            },
            true
        )
        setOrdersForPickup([])
    }

    const handleOrderStatusChange = async newOrderStatus => {
        logGAEvent(GA_CATEGORY, "Change Status Filter", newOrderStatus)
        if (newOrderStatus) {
            setOrderStatus(newOrderStatus)
            setPageNumber(1)
            setSelectAllClicked(false)
            setBulkOrders([])
            await getOrdersList({ newOrderStatus, query: searchQuery })
        }
    }

    const onPageChange = async (event, page) => {
        logGAEvent(GA_CATEGORY, "Change Page", page)
        const newPageNumber = page + 1
        setLoading(true)
        if (!page) {
            setPageNumber(1)
        } else {
            setPageNumber(page + 1)
        }
        setSelectAllClicked(false)
        setBulkOrders([])
        await getOrdersList({ newPageNumber })
        setLoading(false)
    }

    const onRowsPerPageChange = async event => {
        logGAEvent(GA_CATEGORY, "Change Rows per Page", event?.target?.value)
        setLoading(true)
        setRowsPerPage(parseInt(event?.target?.value, 10))
        setPageNumber(1)
        setSelectAllClicked(false)
        setBulkOrders([])
        await getOrdersList({ newRowsPerPage: event?.target?.value })
        setLoading(false)
    }

    const handleOrderSearch = async (query, useCurrentOrderStatus) => {
        setSelectAllClicked(false)
        setBulkOrders([])
        const response = await fetchOrdersSummaryData({ query })
        let newOrderStatus
        if (useCurrentOrderStatus) {
            newOrderStatus = useCurrentOrderStatus
        } else if (response?.readyToBook?.total !== 0) {
            newOrderStatus = "readyToBook"
        } else if (response?.unfulfilled?.total !== 0) {
            newOrderStatus = "actionRequired"
        } else if (response?.closed?.total !== 0) {
            newOrderStatus = "closed"
        } else if (response?.archived?.total !== 0) {
            newOrderStatus = "archived"
        }
        setOrderStatus(newOrderStatus)
        await getOrdersList({ newOrderStatus, query })

        logGAEvent(GA_CATEGORY, "Order Searched")
    }

    const debouncedHandleOrderSearch = useMemo(() => {
        return debounce(handleOrderSearch, 300)
    }, [orderStatus, locationsFilters, startDateFilter, endDateFilter])

    const contextData = {
        onPageChange,
        onRowsPerPageChange,
        ordersList,
        orderCount,
        getOrder,
        archiveOrder,
        uploadFile,
        setOrderStatus,
        orderStatus,
        pageNumber,
        setPageNumber,
        rowsPerPage,
        setRowsPerPage,
        setOrdersToDelete,
        ordersToDelete,
        searchQuery,
        setSearchQuery,
        orderMappingData,
        setOrderMappingData,
        createOrders,
        errors,
        setErrors,
        isBookingOrder,
        setIsBookingOrder,
        bulkOrders,
        setBulkOrders,
        selectAllClicked,
        setSelectAllClicked,
        deleteBulkOrders,
        archiveBulkOrders,
        fetchOrdersSummaryData,
        ordersSummary,
        loading,
        setLoading,
        isFetchingRates,
        setIsFetchingRates,
        markBulkOrdersAsUnfulfilled,
        locationsFilters,
        setLocationsFilters,
        serviceLevelFilters,
        setServiceLevelFilters,
        deliveryTimeFilters,
        setDeliveryTimeFilters,
        applyFilters,
        clearFilters,
        ordersFilterMenuOpen,
        setOrdersFilterMenuOpen,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        getOrdersList,
        handleOrderStatusChange,
        handleOrderSearch,
        debouncedHandleOrderSearch,
        savePickupSelection,
        ordersForPickup,
        setOrdersForPickup,
        ordersRatesHeaders,
        getRateKey,
        initialFetch,
    }

    return (
        <OrdersContext.Provider value={contextData}>
            {children}
        </OrdersContext.Provider>
    )
}
