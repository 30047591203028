import { useRef, useEffect } from "react"
import DOMPurify from "dompurify"
import { get, compact, sortBy, partition, findIndex } from "lodash"
import { defineMessages } from "react-intl"
import qs from "qs"
import { useLocation } from "react-router-dom"

const supportedCountriesMessages = defineMessages({
    US: {
        id: "generalTerms__country__US",
        defaultMessage: "United States of America",
    },
    CA: { id: "generalTerms__country__CA", defaultMessage: "Canada" },
    MX: { id: "generalTerms__country__MX", defaultMessage: "Mexico" },
})

const supportedLanguagesMessages = defineMessages({
    "en-us": { id: "generalTerms__language__en", defaultMessage: "English" },
    "fr-ca": { id: "generalTerms__language__fr", defaultMessage: "Français" },
    "es-mx": { id: "generalTerms__language__es", defaultMessage: "Español" },
})

export const supportedLanguagesCountryMap = {
    "en-us": "US",
    "fr-ca": "CA",
    "es-mx": "MX",
}

export const usePrevious = value => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const useIntervalImmediate = (callback, delay) => {
    const savedCallback = useRef()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (delay !== null && savedCallback.current != null) {
            savedCallback.current()
            let id = setInterval(() => savedCallback.current(), delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

export const useQueryParam = name => {
    const { search } = useLocation()
    const { [name]: param } = qs.parse(search, {
        ignoreQueryPrefix: true,
    })
    return param
}

export const attachmentOptionsMessages = defineMessages({
    selectFileCategory: {
        id: "attachmentOptions__selectFileCategory",
        defaultMessage: "Select File Category",
    },
    commercialInvoice: {
        id: "attachmentOptions__commercialInvoice",
        defaultMessage: "Commercial Invoice",
    },
    customsInvoice: {
        id: "attachmentOptions__customsInvoice",
        defaultMessage: "Customs Invoice",
    },
    nextDayServiceForm: {
        id: "attachmentOptions__nextDayServiceForm",
        defaultMessage: "Next Day Service Form",
    },
    USMCA: {
        id: "attachmentOptions__USMCA",
        defaultMessage: "USMCA / CUSMA / ACEUM / T-MEC",
    },
    certificateOfOrigin: {
        id: "attachmentOptions__certificateOfOrigin",
        defaultMessage: "Certificate of Origin",
    },
    signature: {
        id: "attachmentOptions__signature",
        defaultMessage: "Signature",
    },
    supportInvoice: {
        id: "attachmentOptions__supportInvoice",
        defaultMessage: "Supporting Invoice Document",
    },
    trailerManifest: {
        id: "attachmentOptions__trailerManifest",
        defaultMessage: "Trailer Manifest",
    },
    other: { id: "attachmentOptions__other", defaultMessage: "Other" },
})

const countriesOfOriginMessages = defineMessages({
    US: {
        id: "generalTerms__country__US",
        defaultMessage: "United States of America",
    },
    CA: { id: "generalTerms__country__CA", defaultMessage: "Canada" },
    AF: { id: "generalTerms__country__AF", defaultMessage: "Afghanistan" },
    AX: { id: "generalTerms__country__AX", defaultMessage: "Aland Islands" },
    AL: { id: "generalTerms__country__AL", defaultMessage: "Albania" },
    DZ: { id: "generalTerms__country__DZ", defaultMessage: "Algeria" },
    AS: { id: "generalTerms__country__AS", defaultMessage: "American Samoa" },
    AD: { id: "generalTerms__country__AD", defaultMessage: "Andorra" },
    AO: { id: "generalTerms__country__AO", defaultMessage: "Angola" },
    AI: { id: "generalTerms__country__AI", defaultMessage: "Anguilla" },
    AQ: { id: "generalTerms__country__AQ", defaultMessage: "Antarctica" },
    AG: {
        id: "generalTerms__country__AG",
        defaultMessage: "Antigua and Barbuda",
    },
    AR: { id: "generalTerms__country__AR", defaultMessage: "Argentina" },
    AM: { id: "generalTerms__country__AM", defaultMessage: "Armenia" },
    AW: { id: "generalTerms__country__AW", defaultMessage: "Aruba" },
    AU: { id: "generalTerms__country__AU", defaultMessage: "Australia" },
    AT: { id: "generalTerms__country__AT", defaultMessage: "Austria" },
    AZ: { id: "generalTerms__country__AZ", defaultMessage: "Azerbaijan" },
    BS: { id: "generalTerms__country__BS", defaultMessage: "Bahamas" },
    BH: { id: "generalTerms__country__BH", defaultMessage: "Bahrain" },
    BD: { id: "generalTerms__country__BD", defaultMessage: "Bangladesh" },
    BB: { id: "generalTerms__country__BB", defaultMessage: "Barbados" },
    BY: { id: "generalTerms__country__BY", defaultMessage: "Belarus" },
    BE: { id: "generalTerms__country__BE", defaultMessage: "Belgium" },
    BZ: { id: "generalTerms__country__BZ", defaultMessage: "Belize" },
    BJ: { id: "generalTerms__country__BJ", defaultMessage: "Benin" },
    BM: { id: "generalTerms__country__BM", defaultMessage: "Bermuda" },
    BT: { id: "generalTerms__country__BT", defaultMessage: "Bhutan" },
    BO: { id: "generalTerms__country__BO", defaultMessage: "Bolivia" },
    BQ: {
        id: "generalTerms__country__BQ",
        defaultMessage: "Bonaire, Saint Eustatius and Saba",
    },
    BA: {
        id: "generalTerms__country__BA",
        defaultMessage: "Bosnia and Herzegovina",
    },
    BW: { id: "generalTerms__country__BW", defaultMessage: "Botswana" },
    BV: { id: "generalTerms__country__BV", defaultMessage: "Bouvet Island" },
    BR: { id: "generalTerms__country__BR", defaultMessage: "Brazil" },
    IO: {
        id: "generalTerms__country__IO",
        defaultMessage: "British Indian Ocean Territory",
    },
    VG: {
        id: "generalTerms__country__VG",
        defaultMessage: "British Virgin Islands",
    },
    BN: { id: "generalTerms__country__BN", defaultMessage: "Brunei" },
    BG: { id: "generalTerms__country__BG", defaultMessage: "Bulgaria" },
    BF: { id: "generalTerms__country__BF", defaultMessage: "Burkina Faso" },
    BI: { id: "generalTerms__country__BI", defaultMessage: "Burundi" },
    KH: { id: "generalTerms__country__KH", defaultMessage: "Cambodia" },
    CM: { id: "generalTerms__country__CM", defaultMessage: "Cameroon" },
    CV: { id: "generalTerms__country__CV", defaultMessage: "Cape Verde" },
    KY: { id: "generalTerms__country__KY", defaultMessage: "Cayman Islands" },
    CF: {
        id: "generalTerms__country__CF",
        defaultMessage: "Central African Republic",
    },
    TD: { id: "generalTerms__country__TD", defaultMessage: "Chad" },
    CL: { id: "generalTerms__country__CL", defaultMessage: "Chile" },
    CN: { id: "generalTerms__country__CN", defaultMessage: "China" },
    CX: { id: "generalTerms__country__CX", defaultMessage: "Christmas Island" },
    CC: { id: "generalTerms__country__CC", defaultMessage: "Cocos Islands" },
    CO: { id: "generalTerms__country__CO", defaultMessage: "Colombia" },
    KM: { id: "generalTerms__country__KM", defaultMessage: "Comoros" },
    CK: { id: "generalTerms__country__CK", defaultMessage: "Cook Islands" },
    CR: { id: "generalTerms__country__CR", defaultMessage: "Costa Rica" },
    HR: { id: "generalTerms__country__HR", defaultMessage: "Croatia" },
    CU: { id: "generalTerms__country__CU", defaultMessage: "Cuba" },
    CW: { id: "generalTerms__country__CW", defaultMessage: "Curacao" },
    CY: { id: "generalTerms__country__CY", defaultMessage: "Cyprus" },
    CZ: { id: "generalTerms__country__CZ", defaultMessage: "Czech Republic" },
    CD: {
        id: "generalTerms__country__CD",
        defaultMessage: "Democratic Republic of the Congo",
    },
    DK: { id: "generalTerms__country__DK", defaultMessage: "Denmark" },
    DJ: { id: "generalTerms__country__DJ", defaultMessage: "Djibouti" },
    DM: { id: "generalTerms__country__DM", defaultMessage: "Dominica" },
    DO: {
        id: "generalTerms__country__DO",
        defaultMessage: "Dominican Republic",
    },
    TL: { id: "generalTerms__country__TL", defaultMessage: "East Timor" },
    EC: { id: "generalTerms__country__EC", defaultMessage: "Ecuador" },
    EG: { id: "generalTerms__country__EG", defaultMessage: "Egypt" },
    SV: { id: "generalTerms__country__SV", defaultMessage: "El Salvador" },
    GQ: {
        id: "generalTerms__country__GQ",
        defaultMessage: "Equatorial Guinea",
    },
    ER: { id: "generalTerms__country__ER", defaultMessage: "Eritrea" },
    EE: { id: "generalTerms__country__EE", defaultMessage: "Estonia" },
    ET: { id: "generalTerms__country__ET", defaultMessage: "Ethiopia" },
    FK: { id: "generalTerms__country__FK", defaultMessage: "Falkland Islands" },
    FO: { id: "generalTerms__country__FO", defaultMessage: "Faroe Islands" },
    FJ: { id: "generalTerms__country__FJ", defaultMessage: "Fiji" },
    FI: { id: "generalTerms__country__FI", defaultMessage: "Finland" },
    FR: { id: "generalTerms__country__FR", defaultMessage: "France" },
    GF: { id: "generalTerms__country__GF", defaultMessage: "French Guiana" },
    PF: { id: "generalTerms__country__PF", defaultMessage: "French Polynesia" },
    TF: {
        id: "generalTerms__country__TF",
        defaultMessage: "French Southern Territories",
    },
    GA: { id: "generalTerms__country__GA", defaultMessage: "Gabon" },
    GM: { id: "generalTerms__country__GM", defaultMessage: "Gambia" },
    GE: { id: "generalTerms__country__GE", defaultMessage: "Georgia" },
    DE: { id: "generalTerms__country__DE", defaultMessage: "Germany" },
    GH: { id: "generalTerms__country__GH", defaultMessage: "Ghana" },
    GI: { id: "generalTerms__country__GI", defaultMessage: "Gibraltar" },
    GR: { id: "generalTerms__country__GR", defaultMessage: "Greece" },
    GL: { id: "generalTerms__country__GL", defaultMessage: "Greenland" },
    GD: { id: "generalTerms__country__GD", defaultMessage: "Grenada" },
    GP: { id: "generalTerms__country__GP", defaultMessage: "Guadeloupe" },
    GU: { id: "generalTerms__country__GU", defaultMessage: "Guam" },
    GT: { id: "generalTerms__country__GT", defaultMessage: "Guatemala" },
    GG: { id: "generalTerms__country__GG", defaultMessage: "Guernsey" },
    GN: { id: "generalTerms__country__GN", defaultMessage: "Guinea" },
    GW: { id: "generalTerms__country__GW", defaultMessage: "Guinea-Bissau" },
    GY: { id: "generalTerms__country__GY", defaultMessage: "Guyana" },
    HT: { id: "generalTerms__country__HT", defaultMessage: "Haiti" },
    HM: {
        id: "generalTerms__country__HM",
        defaultMessage: "Heard Island and McDonald Islands",
    },
    HN: { id: "generalTerms__country__HN", defaultMessage: "Honduras" },
    HK: { id: "generalTerms__country__HK", defaultMessage: "Hong Kong" },
    HU: { id: "generalTerms__country__HU", defaultMessage: "Hungary" },
    IS: { id: "generalTerms__country__IS", defaultMessage: "Iceland" },
    IN: { id: "generalTerms__country__IN", defaultMessage: "India" },
    ID: { id: "generalTerms__country__ID", defaultMessage: "Indonesia" },
    IR: { id: "generalTerms__country__IR", defaultMessage: "Iran" },
    IQ: { id: "generalTerms__country__IQ", defaultMessage: "Iraq" },
    IE: { id: "generalTerms__country__IE", defaultMessage: "Ireland" },
    IM: { id: "generalTerms__country__IM", defaultMessage: "Isle of Man" },
    IL: { id: "generalTerms__country__IL", defaultMessage: "Israel" },
    IT: { id: "generalTerms__country__IT", defaultMessage: "Italy" },
    CI: { id: "generalTerms__country__CI", defaultMessage: "Ivory Coast" },
    JM: { id: "generalTerms__country__JM", defaultMessage: "Jamaica" },
    JP: { id: "generalTerms__country__JP", defaultMessage: "Japan" },
    JE: { id: "generalTerms__country__JE", defaultMessage: "Jersey" },
    JO: { id: "generalTerms__country__JO", defaultMessage: "Jordan" },
    KZ: { id: "generalTerms__country__KZ", defaultMessage: "Kazakhstan" },
    KE: { id: "generalTerms__country__KE", defaultMessage: "Kenya" },
    KI: { id: "generalTerms__country__KI", defaultMessage: "Kiribati" },
    XK: { id: "generalTerms__country__XK", defaultMessage: "Kosovo" },
    KW: { id: "generalTerms__country__KW", defaultMessage: "Kuwait" },
    KG: { id: "generalTerms__country__KG", defaultMessage: "Kyrgyzstan" },
    LA: { id: "generalTerms__country__LA", defaultMessage: "Laos" },
    LV: { id: "generalTerms__country__LV", defaultMessage: "Latvia" },
    LB: { id: "generalTerms__country__LB", defaultMessage: "Lebanon" },
    LS: { id: "generalTerms__country__LS", defaultMessage: "Lesotho" },
    LR: { id: "generalTerms__country__LR", defaultMessage: "Liberia" },
    LY: { id: "generalTerms__country__LY", defaultMessage: "Libya" },
    LI: { id: "generalTerms__country__LI", defaultMessage: "Liechtenstein" },
    LT: { id: "generalTerms__country__LT", defaultMessage: "Lithuania" },
    LU: { id: "generalTerms__country__LU", defaultMessage: "Luxembourg" },
    MO: { id: "generalTerms__country__MO", defaultMessage: "Macao" },
    MK: { id: "generalTerms__country__MK", defaultMessage: "North Macedonia" },
    MG: { id: "generalTerms__country__MG", defaultMessage: "Madagascar" },
    MW: { id: "generalTerms__country__MW", defaultMessage: "Malawi" },
    MY: { id: "generalTerms__country__MY", defaultMessage: "Malaysia" },
    MV: { id: "generalTerms__country__MV", defaultMessage: "Maldives" },
    ML: { id: "generalTerms__country__ML", defaultMessage: "Mali" },
    MT: { id: "generalTerms__country__MT", defaultMessage: "Malta" },
    MH: { id: "generalTerms__country__MH", defaultMessage: "Marshall Islands" },
    MQ: { id: "generalTerms__country__MQ", defaultMessage: "Martinique" },
    MR: { id: "generalTerms__country__MR", defaultMessage: "Mauritania" },
    MU: { id: "generalTerms__country__MU", defaultMessage: "Mauritius" },
    YT: { id: "generalTerms__country__YT", defaultMessage: "Mayotte" },
    MX: { id: "generalTerms__country__MX", defaultMessage: "Mexico" },
    FM: { id: "generalTerms__country__FM", defaultMessage: "Micronesia" },
    MD: { id: "generalTerms__country__MD", defaultMessage: "Moldova" },
    MC: { id: "generalTerms__country__MC", defaultMessage: "Monaco" },
    MN: { id: "generalTerms__country__MN", defaultMessage: "Mongolia" },
    ME: { id: "generalTerms__country__ME", defaultMessage: "Montenegro" },
    MS: { id: "generalTerms__country__MS", defaultMessage: "Montserrat" },
    MA: { id: "generalTerms__country__MA", defaultMessage: "Morocco" },
    MZ: { id: "generalTerms__country__MZ", defaultMessage: "Mozambique" },
    MM: { id: "generalTerms__country__MM", defaultMessage: "Myanmar" },
    NA: { id: "generalTerms__country__NA", defaultMessage: "Namibia" },
    NR: { id: "generalTerms__country__NR", defaultMessage: "Nauru" },
    NP: { id: "generalTerms__country__NP", defaultMessage: "Nepal" },
    NL: { id: "generalTerms__country__NL", defaultMessage: "Netherlands" },
    NC: { id: "generalTerms__country__NC", defaultMessage: "New Caledonia" },
    NZ: { id: "generalTerms__country__NZ", defaultMessage: "New Zealand" },
    NI: { id: "generalTerms__country__NI", defaultMessage: "Nicaragua" },
    NE: { id: "generalTerms__country__NE", defaultMessage: "Niger" },
    NG: { id: "generalTerms__country__NG", defaultMessage: "Nigeria" },
    NU: { id: "generalTerms__country__NU", defaultMessage: "Niue" },
    NF: { id: "generalTerms__country__NF", defaultMessage: "Norfolk Island" },
    KP: { id: "generalTerms__country__KP", defaultMessage: "North Korea" },
    MP: {
        id: "generalTerms__country__MP",
        defaultMessage: "Northern Mariana Islands",
    },
    NO: { id: "generalTerms__country__NO", defaultMessage: "Norway" },
    OM: { id: "generalTerms__country__OM", defaultMessage: "Oman" },
    PK: { id: "generalTerms__country__PK", defaultMessage: "Pakistan" },
    PW: { id: "generalTerms__country__PW", defaultMessage: "Palau" },
    PS: {
        id: "generalTerms__country__PS",
        defaultMessage: "Palestinian Territory",
    },
    PA: { id: "generalTerms__country__PA", defaultMessage: "Panama" },
    PG: { id: "generalTerms__country__PG", defaultMessage: "Papua New Guinea" },
    PY: { id: "generalTerms__country__PY", defaultMessage: "Paraguay" },
    PE: { id: "generalTerms__country__PE", defaultMessage: "Peru" },
    PH: { id: "generalTerms__country__PH", defaultMessage: "Philippines" },
    PN: { id: "generalTerms__country__PN", defaultMessage: "Pitcairn" },
    PL: { id: "generalTerms__country__PL", defaultMessage: "Poland" },
    PT: { id: "generalTerms__country__PT", defaultMessage: "Portugal" },
    PR: { id: "generalTerms__country__PR", defaultMessage: "Puerto Rico" },
    QA: { id: "generalTerms__country__QA", defaultMessage: "Qatar" },
    CG: {
        id: "generalTerms__country__CG",
        defaultMessage: "Republic of the Congo",
    },
    RE: { id: "generalTerms__country__RE", defaultMessage: "Reunion" },
    RO: { id: "generalTerms__country__RO", defaultMessage: "Romania" },
    RU: { id: "generalTerms__country__RU", defaultMessage: "Russia" },
    RW: { id: "generalTerms__country__RW", defaultMessage: "Rwanda" },
    BL: { id: "generalTerms__country__BL", defaultMessage: "Saint Barthelemy" },
    SH: { id: "generalTerms__country__SH", defaultMessage: "Saint Helena" },
    KN: {
        id: "generalTerms__country__KN",
        defaultMessage: "Saint Kitts and Nevis",
    },
    LC: { id: "generalTerms__country__LC", defaultMessage: "Saint Lucia" },
    MF: { id: "generalTerms__country__MF", defaultMessage: "Saint Martin" },
    PM: {
        id: "generalTerms__country__PM",
        defaultMessage: "Saint Pierre and Miquelon",
    },
    VC: {
        id: "generalTerms__country__VC",
        defaultMessage: "Saint Vincent and the Grenadines",
    },
    WS: { id: "generalTerms__country__WS", defaultMessage: "Samoa" },
    SM: { id: "generalTerms__country__SM", defaultMessage: "San Marino" },
    ST: {
        id: "generalTerms__country__ST",
        defaultMessage: "Sao Tome and Principe",
    },
    SA: { id: "generalTerms__country__SA", defaultMessage: "Saudi Arabia" },
    SN: { id: "generalTerms__country__SN", defaultMessage: "Senegal" },
    RS: { id: "generalTerms__country__RS", defaultMessage: "Serbia" },
    SC: { id: "generalTerms__country__SC", defaultMessage: "Seychelles" },
    SL: { id: "generalTerms__country__SL", defaultMessage: "Sierra Leone" },
    SG: { id: "generalTerms__country__SG", defaultMessage: "Singapore" },
    SX: { id: "generalTerms__country__SX", defaultMessage: "Sint Maarten" },
    SK: { id: "generalTerms__country__SK", defaultMessage: "Slovakia" },
    SI: { id: "generalTerms__country__SI", defaultMessage: "Slovenia" },
    SB: { id: "generalTerms__country__SB", defaultMessage: "Solomon Islands" },
    SO: { id: "generalTerms__country__SO", defaultMessage: "Somalia" },
    ZA: { id: "generalTerms__country__ZA", defaultMessage: "South Africa" },
    GS: {
        id: "generalTerms__country__GS",
        defaultMessage: "South Georgia and the South Sandwich Islands",
    },
    KR: { id: "generalTerms__country__KR", defaultMessage: "South Korea" },
    SS: { id: "generalTerms__country__SS", defaultMessage: "South Sudan" },
    ES: { id: "generalTerms__country__ES", defaultMessage: "Spain" },
    LK: { id: "generalTerms__country__LK", defaultMessage: "Sri Lanka" },
    SD: { id: "generalTerms__country__SD", defaultMessage: "Sudan" },
    SR: { id: "generalTerms__country__SR", defaultMessage: "Suriname" },
    SJ: {
        id: "generalTerms__country__SJ",
        defaultMessage: "Svalbard and Jan Mayen",
    },
    SZ: { id: "generalTerms__country__SZ", defaultMessage: "Swaziland" },
    SE: { id: "generalTerms__country__SE", defaultMessage: "Sweden" },
    CH: { id: "generalTerms__country__CH", defaultMessage: "Switzerland" },
    SY: { id: "generalTerms__country__SY", defaultMessage: "Syria" },
    TW: { id: "generalTerms__country__TW", defaultMessage: "Taiwan" },
    TJ: { id: "generalTerms__country__TJ", defaultMessage: "Tajikistan" },
    TZ: { id: "generalTerms__country__TZ", defaultMessage: "Tanzania" },
    TH: { id: "generalTerms__country__TH", defaultMessage: "Thailand" },
    TG: { id: "generalTerms__country__TG", defaultMessage: "Togo" },
    TK: { id: "generalTerms__country__TK", defaultMessage: "Tokelau" },
    TO: { id: "generalTerms__country__TO", defaultMessage: "Tonga" },
    TT: {
        id: "generalTerms__country__TT",
        defaultMessage: "Trinidad and Tobago",
    },
    TN: { id: "generalTerms__country__TN", defaultMessage: "Tunisia" },
    TR: { id: "generalTerms__country__TR", defaultMessage: "Turkey" },
    TM: { id: "generalTerms__country__TM", defaultMessage: "Turkmenistan" },
    TC: {
        id: "generalTerms__country__TC",
        defaultMessage: "Turks and Caicos Islands",
    },
    TV: { id: "generalTerms__country__TV", defaultMessage: "Tuvalu" },
    VI: {
        id: "generalTerms__country__VI",
        defaultMessage: "U.S. Virgin Islands",
    },
    UG: { id: "generalTerms__country__UG", defaultMessage: "Uganda" },
    UA: { id: "generalTerms__country__UA", defaultMessage: "Ukraine" },
    AE: {
        id: "generalTerms__country__AE",
        defaultMessage: "United Arab Emirates",
    },
    GB: { id: "generalTerms__country__GB", defaultMessage: "United Kingdom" },
    UM: {
        id: "generalTerms__country__UM",
        defaultMessage: "United States Minor Outlying Islands",
    },
    UY: { id: "generalTerms__country__UY", defaultMessage: "Uruguay" },
    UZ: { id: "generalTerms__country__UZ", defaultMessage: "Uzbekistan" },
    VU: { id: "generalTerms__country__VU", defaultMessage: "Vanuatu" },
    VA: { id: "generalTerms__country__VA", defaultMessage: "Vatican" },
    VE: { id: "generalTerms__country__VE", defaultMessage: "Venezuela" },
    VN: { id: "generalTerms__country__VN", defaultMessage: "Vietnam" },
    WF: {
        id: "generalTerms__country__WF",
        defaultMessage: "Wallis and Futuna",
    },
    EH: { id: "generalTerms__country__EH", defaultMessage: "Western Sahara" },
    YE: { id: "generalTerms__country__YE", defaultMessage: "Yemen" },
    ZM: { id: "generalTerms__country__ZM", defaultMessage: "Zambia" },
    ZW: { id: "generalTerms__country__ZW", defaultMessage: "Zimbabwe" },
})

const domesticOffShoreStateLabels = defineMessages({
    PR: { id: "generalTerms__state__PR", defaultMessage: "Puerto Rico" },
    AK: { id: "generalTerms__state__AK", defaultMessage: "Alaska" },
    HI: { id: "generalTerms__state__HI", defaultMessage: "Hawaii" },
})

export const attachmentOptions = [
    {
        value: undefined,
        label: attachmentOptionsMessages["selectFileCategory"],
        disabled: true,
    },
    {
        value: "commercialInvoice",
        label: attachmentOptionsMessages["commercialInvoice"],
    },
    {
        value: "customsInvoice",
        label: attachmentOptionsMessages["customsInvoice"],
    },
    {
        value: "nextDayServiceForm",
        label: attachmentOptionsMessages["nextDayServiceForm"],
    },
    {
        value: "USMCA",
        label: attachmentOptionsMessages["USMCA"],
    },
    {
        value: "certificateOfOrigin",
        label: attachmentOptionsMessages["certificateOfOrigin"],
    },
    {
        value: "supportInvoice",
        label: attachmentOptionsMessages["supportInvoice"],
    },
    {
        value: "trailerManifest",
        label: attachmentOptionsMessages["trailerManifest"],
    },
    { value: "other", label: attachmentOptionsMessages["other"] },
]

export const attachmentIntrMexicoOptions = [
    {
        value: undefined,
        label: attachmentOptionsMessages["selectFileCategory"],
        disabled: true,
    },
    {
        value: "commercialInvoice",
        label: attachmentOptionsMessages["commercialInvoice"],
    },
    {
        value: "supportInvoice",
        label: attachmentOptionsMessages["supportInvoice"],
    },
]

export const attachmentPROptions = [
    {
        value: undefined,
        label: attachmentOptionsMessages["selectFileCategory"],
        disabled: true,
    },
    {
        value: "commercialInvoice",
        label: attachmentOptionsMessages["commercialInvoice"],
    },
]

export const isVolumeLTL = state =>
    get(state, "form.quote.values.volumeLTL") === true

export const printComponent = () => window.print()

const messages = defineMessages({
    FXFE: {
        id: "carrierName.FedExFreightPrioritySuffix",
        defaultMessage: "Priority",
    },
    FXNL: {
        id: "carrierName.FedExFreightEconomySuffix",
        defaultMessage: "Economy",
    },
})

const fedExCodes = {
    FXFE: { ...messages.FXFE, name: messages.FXFE.defaultMessage },
    FXNL: { ...messages.FXNL, name: messages.FXNL.defaultMessage },
}

export const isFedExCarrier = code => fedExCodes[code]

export function loadData(key, session = false) {
    const storage = session ? sessionStorage : localStorage
    const data = storage.getItem(key)
    if (data) return JSON.parse(data)
    return undefined
}

export function saveData(key, object, session = false) {
    const storage = session ? sessionStorage : localStorage
    if (object) {
        storage.setItem(key, JSON.stringify(object))
    } else {
        storage.removeItem(key)
    }
}

export function setUpStorageChangeListener(key, action) {
    const eventListener = e => {
        if (e.key !== key || e.oldValue === e.newValue) return
        action(e.newValue)
    }
    window.addEventListener("storage", eventListener)
    return eventListener
}

export function removeStorageChangeListener(eventListener) {
    window.removeEventListener("storage", eventListener)
}

export const cleanupObjectDeep = obj =>
    Object.keys(obj).reduce((prev, key) => {
        let targetObj = obj[key]
        if (targetObj !== "" && targetObj !== null && targetObj !== undefined) {
            if (Array.isArray(obj[key]) || typeof targetObj === "object") {
                targetObj = cleanupObjectDeep(targetObj)
                if (
                    targetObj === "" ||
                    targetObj === null ||
                    targetObj === undefined
                ) {
                    return prev
                }
            }
            prev[key] = obj[key]
        }
        return prev
    }, {})

export const cleanupObject = obj =>
    Object.keys(obj).reduce((prev, key) => {
        if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
            prev[key] = obj[key]
        return prev
    }, {})

export function initPurifier() {
    DOMPurify.addHook("afterSanitizeAttributes", node => {
        if ("target" in node) {
            node.setAttribute("target", "_blank")
        }
        if (
            !node.hasAttribute("target") &&
            (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
        ) {
            node.setAttribute("xlink:show", "new")
        }
    })
}

export const join = (prefix, name) => compact([prefix, name]).join(".")

export function reduxFormProps(context) {
    const sectionPrefix =
        context?._reduxForm?.sectionPrefix || context?.sectionPrefix
    const form = context?._reduxForm?.form || context?.form
    return { form, sectionPrefix }
}

export function reduxFormAction(context, fields, action, values, prefix) {
    const { sectionPrefix, form } = reduxFormProps(context)
    fields.forEach(name => {
        const fieldName = compact([sectionPrefix, prefix, name]).join(".")
        const value = values ? get(values, name) : undefined
        action(form, fieldName, value)
    })
    return { form, sectionPrefix }
}

export const escapeString = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")

export const getShareKey = state =>
    state && state.shareStatus && state.shareStatus.shareKey

export const isDirectionShipper = type => type === "SHIPPER"
export const isDirectionConsignee = type => type === "CONSIGNEE"

export function getObjectIdTimestamp(object) {
    const timestamp = object._id.toString().substring(0, 8)
    return new Date(parseInt(timestamp, 16) * 1000)
}

export function sortByStringProperty(property, language) {
    return (a, b) => a[property[language]].localeCompare(b[property])
}

export function sortByObjectIdDate(a, b) {
    const timestampA = getObjectIdTimestamp(a)
    const timestampB = getObjectIdTimestamp(b)
    return timestampB - timestampA
}

export function sortRateCharges(a, b) {
    const order = ["ITEM", "MIN", "GFC", "DSC", "FSC"]
    const indexA = order.indexOf(a.code)
    const indexB = order.indexOf(b.code)
    return (indexA === -1) - (indexB === -1) || indexA > indexB
}

export function getCarrierServiceDisplayName(
    carrierCode,
    serviceLevel,
    mode,
    carriersList
) {
    if (!carriersList[carrierCode]) return carrierCode
    const isVolume = mode === "VOLUME_LTL" ? "Volume Services" : ""
    const serviceLevelCode = serviceLevel.code
    const carriersName = {
        [carrierCode]: `${carriersList[carrierCode].name} ${isVolume}`,
        FXFE: `FedEx Freight ${isVolume || "Priority"}`,
        FXNL: `FedEx Freight ${isVolume || "Economy"}`,
        domestic_offshore: "Domestic Offshore",
    }
    let displayName = carriersName[carrierCode]
    if (isVolume && ["TLX", "TLS", "EXCL"].includes(serviceLevelCode)) {
        displayName +=
            serviceLevelCode === "EXCL"
                ? " Exclusive Use"
                : ` ${serviceLevelCode}`
    }
    return displayName
}

/* eslint-disable no-plusplus, no-bitwise */
export function hashCode(str) {
    const len = str.length
    let hash = 0
    if (len === 0) return hash
    let i
    for (i = 0; i < len; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i)
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}
/* eslint-enable no-plusplus, no-bitwise */

export function reloadApp(e) {
    window.location.reload(true)
    e.preventDefault()
}

export function isRateSelectedForShare(shareSelections, rate, carrierCode) {
    const quotesMatchingCarrier = shareSelections.filter(
        q => q.name === carrierCode
    )
    const matchingRate = quotesMatchingCarrier.findIndex(
        q =>
            q.alternateRateQuote === rate.index &&
            rate?.mode === q?.mode &&
            q.direction === rate.direction
    )
    return matchingRate !== -1
}

export const supportedCurrencies = [
    { value: "USD", label: "USD" },
    { value: "CAD", label: "CAD" },
    { value: "MXN", label: "MXN" },
]

export const supportedCountryCodes = [
    { value: "US", label: "US" },
    { value: "CA", label: "CA" },
    { value: "MX", label: "MX" },
]

export const usDomesticOffshoreStateOptions = [
    // { value: "AK", label: domesticOffShoreStateLabels["AK"] },
    {
        value: "PR",
        label: domesticOffShoreStateLabels["PR"],
    },
    // { value: "HI", label: domesticOffShoreStateLabels["HI"] },
]

export const isPercentage = [
    { value: true, label: "% off" },
    { value: false, label: "$ off" },
]

export const supportedCountries = Object.keys(
    supportedCountriesMessages
).map(value => ({ value, label: supportedCountriesMessages[value] }))

export const countriesOfOrigin = Object.keys(
    countriesOfOriginMessages
).map(value => ({ value, label: countriesOfOriginMessages[value] }))

export const provideLocaleSortedOptionsList = (
    intl,
    options,
    topItemKeys = []
) => {
    const partitionedOptions = partition(options, item =>
        topItemKeys.includes(item.value)
    )
    const topItems = sortBy(partitionedOptions[0], [
        item => findIndex(item.value, "value"),
    ]).map(item => ({ ...item, label: intl.formatMessage(item.label) }))
    const otherItems = partitionedOptions[1]
        .map(item => ({ ...item, label: intl.formatMessage(item.label) }))
        .sort((a, b) => a.label.localeCompare(b.label, intl.locale))
    return [...topItems, ...otherItems]
}

export const supportedLanguages = Object.keys(
    supportedLanguagesMessages
).map(value => ({ value, label: supportedLanguagesMessages[value] }))
